<script>
import axios from "axios";
// import { SimpleBar } from "simplebar-vue3";

import { authComputed } from "@/state/helpers";

// import Autocomplete from './Autocomplete.vue';

import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';
import { AisConfigure, AisHits } from 'vue-instantsearch/vue3/es';
// import { AisHighlight } from 'vue-instantsearch/vue3/es';

/**
 * Nav-bar Component
 */
export default {
  components: {
    // Autocomplete,
    // SimpleBar,
    AisConfigure,
    // AisHighlight,
    AisHits,
  },
  data() {
    return {
      notificationsCount: 0,
      searchClient: algoliasearch(
        'EGI088BLBI',
        'a649ebe654c9231660f327d32bcc687f'
      ),
      lang: "en",
      text: null,
      flag: null,
      value: null,
       languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/chaina.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ]
    };
  },
  mounted() {
  },
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
   
    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
        this.$router.push({
          name: "default",
        });
      });
    },
    getRoute(assetType) {
      if (assetType == 'stock') {
        return 'stocks-symbol'
      }
      if (assetType == 'crypto') {
        return 'crypto-symbol'
      }
      if (assetType == 'etf') {
        return 'etfs-symbol'
      }
      else return 'dashboard'
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<style scoped>
.searchbar {
  /* margin-top: 15px; */
  height: 40px;
  /* overflow:scroll; */
}
.badge-symbol {
  /* font-family: "Fira Code", "Lucida Console", monospace; */
  font-size: 13px;
  font-weight: 500;
  padding: 0.3em 0.4em;
  background-color: #e0e6eb;
  color: #7A8698;
  text-transform: uppercase;
}
.badge-stock {
  /* font-family: "Fira Code", "Lucida Console", monospace; */
  font-size: 12px;
  font-weight: 300;
  padding: 0.3em 0.4em;
  background-color: #D1F2EB;
  color: #7A8698;
  text-transform: capitalize;
}
.badge-crypto {
  /* font-family: "Fira Code", "Lucida Console", monospace; */
  font-size: 12px;
  font-weight: 300;
  padding: 0.3em 0.4em;
  background-color: #FCF3CF;
  color: #7A8698;
  text-transform: capitalize;
}
.badge-beta {
    font-family: "Fira Code", "Lucida Console", monospace;
    font-size: 13px;
    color: #495057;
    background-color: #FFF9A6;
    font-weight: 400;
    padding: 0.5em 0.5em;
  }
.ais-search-box {
  width: 200px;
}
</style>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex align-items-center">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-light.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="19" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <!-- <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input
              type="text"
              class="form-control"
              :placeholder="$t('navbar.search.text')"
            />
            <span class="bx bx-search-alt"></span>
          </div>
        </form> -->

        <b-dropdown
          menu-class="dropdown-menu-lg"
          toggle-class="header-item"
          variant="black"
          no-caret
        >
          <template v-slot:button-content>
            <i class="bx bx-search font-size-22"></i>
          </template>
          <div class="searchbar">
            <ais-instant-search 
              :search-client="searchClient" 
              index-name="bulldata_symbols"
            >
              <ais-search-box class="ais-search-box" />
              <ais-state-results>
                <template v-slot="{ state: { query } }">
                  <ais-hits v-show="query.length > 0">
                    <template v-slot:item="{ item }">
                    <router-link 
                      :to="{ 
                        name: getRoute(item.asset_type), 
                        params: { symbol: item.symbol }
                      }">
                      <!-- <ais-highlight attribute="name" :hit="item" /> -->
                      {{ item.name }}
                      <div class="badge badge-symbol ms-1">
                        <!-- <ais-highlight attribute="symbol" :hit="item" /> -->
                        {{ item.symbol }}
                      </div>
                      <div 
                        class="badge ms-2"
                        :class="{
                          'badge-stock': item.asset_type == 'stock',
                          'badge-crypto': item.asset_type == 'crypto'
                        }"
                      >
                        <!-- <ais-highlight attribute="symbol" :hit="item" /> -->
                        {{ item.asset_type }}
                      </div>
                    </router-link>
                    </template>
                  </ais-hits>
                </template>
              </ais-state-results>
              <ais-configure
                :hits-per-page.camel="8"
              />
            </ais-instant-search>
          </div>
        </b-dropdown>
      </div>

      <div class="d-flex align-items-center">
        <div class="px-3">
          <span class="badge badge-beta px-3">BETA</span>
        </div>

        <!-- Language Selector -->
        <!-- <select v-model="$i18n.locale" class="lang-dropdown border-0">
          <option v-for="(locale, i) in languages" :key="`locale-${i}`" :value="locale.language">
            {{ locale.title }}
          </option>
        </select> -->

        <div class="d-none d-lg-inline-block px-2">
          <button
            type="button"
            class="btn header-item noti-icon p-0"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <!-- <b-dropdown
          right
          menu-class="dropdown-menu-lg dropdown-menu-end"
          toggle-class="header-item noti-icon"
          variant="black"
          no-caret
        >
          <template v-slot:button-content>
            <i class="bx bx-bell"></i>
            <span class="badge bg-danger rounded-pill" v-if="notificationsCount > 0">{{
              notificationsCount
            }}</span>
          </template>

          <div class="p-2 border-top d-grid text-center">
            No notifications.
          </div>
        </b-dropdown> -->

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          menu-class="dropdown-menu-end"
          no-caret
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              :src=userData.photoURL
              v-if="userData.photoURL"
              alt=""
            />
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/default_light_blue.png"
              alt=""
              v-else
            />
            <span class="d-none d-xl-inline-block ms-1">
              <div v-if="currentUser">
                {{ currentUser.displayName }}
              </div>
            </span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-dropdown-item>
            <router-link to="/profile" v-slot="{ navigate }" custom>
              <span @click="navigate" @keypress.enter="navigate">
                <i class="bx bx-user font-size-16 align-middle me-1"></i>
                Profile
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <router-link to="/logout" class="dropdown-item text-danger">
            <i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.henry.list.logout") }}
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
