<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-6">{{ new Date().getFullYear() }} © Bulldata.ai</div>
        <div class="col-6 text-end">
          <!-- Button trigger modal -->
          <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Report a bug
          </button>
          <!-- Modal -->
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">Report a bug</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <b-form @submit="onSubmit" @reset="onReset">
                  <div class="modal-body">
                    <b-form-textarea
                      id="bugDetails"
                      v-model="bugDetails"
                      placeholder="Thanks for taking the time to help us make this site better! Please describe the bug."
                      rows="3"
                      max-rows="10"
                      required
                    ></b-form-textarea>
                  </div>
                  <div class="modal-footer">
                    <div class="container mx-0">
                      <b-button type="reset" class="btn btn-secondary me-2" data-bs-dismiss="modal">Close</b-button>
                      <b-button type="submit" variant="primary">
                        Submit
                        <i class="bx bx-loader bx-spin align-middle ms-1" v-show="bugLoading"></i>
                      </b-button>
                    </div>
                    <div class="container text-start mx-0">
                      <b-alert v-model="bugSent" dismissible variant="success">
                        Bug report sent successfully.
                      </b-alert >
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>

<script>
import { authComputed } from "@/state/helpers";
import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import "firebase/compat/firestore";

export default {
  data() {
    return {
      bugDetails: '',
      bugLoading: false,
      bugSent: false,
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.bugLoading = true
      this.reportBug(this.bugDetails)
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.bugDetails = ''
      this.bugLoading = false
      this.bugSent = false
    },
  
    // report bug
    async reportBug(bugDetails) {
      await firebase
        .firestore()
        .collection('bugReports')
        .add({
          userId: this.currentUser.uid,
          userEmail: this.currentUser.email,
          reportedDate: Math.floor(new Date().getTime() / 1000),
          bugDetails: bugDetails,
        })
        .then(() => {
          this.bugLoading = false
          this.bugSent = true
          console.log('Bug report sent successfully.')
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>