<script>
import { loadStripe } from '@stripe/stripe-js'
import firebase from 'firebase/compat/app';
import "firebase/firestore";
import { authComputed } from "@/state/helpers";

const stripeInit = loadStripe(process.env.VUE_APP_STRIPE_PERISHABLE_KEY)

export default {
  components: {
  },
  data() {
    return {
      disableButtons: false,
      paymentId: '',
      payment: {},
      payments: [],
      session: {},
      tier: 'premium',
      term: 'yearly',
      terms: [
        { text: 'Billed Yearly', value: 'yearly' },
        { text: 'Billed Monthly', value: 'monthly' }
      ],
      currency: 'usd',
      currencies: [
        { text: 'EUR', value: 'eur' },
        { text: 'USD', value: 'usd' }
      ],
      products: {
        basic: {
          monthly: {
            eur: '',
            usd: '',
          },
          yearly: {
            eur: '',
            usd: 'price_1NbkNoFWSspN01EY8uWWosUG',
          },
        },
        premium: {
          monthly: {
            eur: '',
            usd: '',
          },
          yearly: {
            eur: '',
            usd: 'price_1NbkODFWSspN01EYDHGRyaCK',
          },
        },
        pro: {
          monthly: {
            eur: '',
            usd: '',
          },
          yearly: {
            eur: '',
            usd: 'price_1NbkOIFWSspN01EYSIRZEKxC',
          },
        },
      },
    }
  },
  computed: {
    ...authComputed,
  },  
  methods: {
    // need to create Stripe customer in Firestore first before this will work
    async createPayment(tier, term, currency) {
      this.disableButtons = true
      this.paymentId = this.generateId()
      console.log(tier + term + currency)
      console.log('paymentId: ' + this.paymentId)
      await firebase.firestore()
        .collection('stripe_customers')
        .doc(this.currentUser.uid)
        .collection('payments')
        .doc(this.paymentId)
        .set({
          price: this.products[tier][term][currency],
          quantity: 1,
          tier: tier,
        })
        .catch(err => console.log(err))
      this.listenForSessionId()
    },

    generateId() {
      return 'p' + Math.floor(Math.random() * (99999999999 - 10000000000 + 1) + 10000000000).toString()
    },

    async listenForSessionId() {
      await firebase.firestore()
        .collection('stripe_customers')
        .doc(this.currentUser.uid)
        .collection('payments')
        .doc(this.paymentId)
        .onSnapshot((doc) => {
          this.payment = doc.data()
          console.log(doc.data())
        })
    },

    async redirectToCheckout() {
      await this.payment.id
      stripeInit
        .then(stripe => {
          stripe.redirectToCheckout({
            sessionId: this.payment.id,
          }).then(() => {
            console.log('Redirecting to Stripe')
          }).catch(err => {
            console.log(err)
          })
        })
    },
  },

  watch: {
    payment: function () {
      if (this.payment.id) {
        console.log('Checkout Session ID: ', this.payment.id)
        this.redirectToCheckout()
      }
    },
  },
};
</script>

<style scoped>
.signup-button {
  background-color: #006E33;
  border-color: #006E33;
  /* padding-inline: 20px; */
  margin-bottom: 30px;
}
</style>

<template>
  <div v-if="!disableButtons">
    <b-dropdown
      class="signup-button"
      variant="success"
      text="Sign Up"
    >
      <b-dropdown-item @click="createPayment('basic', term, currency);">Basic</b-dropdown-item>
      <b-dropdown-item @click="createPayment('premium', term, currency);">Premium</b-dropdown-item>
      <b-dropdown-item @click="createPayment('pro', term, currency);">Pro</b-dropdown-item>
    </b-dropdown>
  </div>
  <div v-else>
    <b-spinner variant="success" label="Loading"></b-spinner>
  </div>
</template>
