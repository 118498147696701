<script>
import { SimpleBar } from "simplebar-vue3"
import SideNav from "./side-nav";
import { layoutComputed } from "@/state/helpers";
import { authComputed } from "@/state/helpers";
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import Subscribe from "@/components/subscribe";

/**
 * Sidebar component
 */
export default {
  components: { SimpleBar, SideNav, Subscribe },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    dataLoaded () {
      if(this.userData.preferredTerm) {
        return true
      }
      else return false
    },
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      scopes: {
        intraday: {
          value: "intraday",
          snake: "intraday",
          train: "Intraday",
          kebab: "intraday"
        },
        shortterm: {
          value: "shortterm",
          snake: "short_term",
          train: "Short-Term",
          kebab: "short-term"
        },
        mediumterm: {
          value: "mediumterm",
          snake: "medium_term",
          train: "Medium-Term",
          kebab: "medium-term"
        },
        longterm: {
          value: "longterm",
          snake: "long_term",
          train: "Long-Term",
          kebab: "long-term",
        }
      },
    };
  },
  methods: {
    onRoutechange() {
     setTimeout(() => {
        if(document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if(this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      }, 300);
    },
    async setTerm(newTerm) {
      if (this.userData.preferredTerm != newTerm) {
        this.$store.commit('auth/UPDATE_TERM', newTerm);
        await firebase
          .firestore()
          .collection('users')
          .doc(this.userData.uid)
          .update({
              preferredTerm: newTerm
          })
          .then(() => {
              console.log(`Term updated: ${newTerm}`);
          }, (error) => {
              console.log(error);
          });
      }
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "dark");
              document.body.removeAttribute("data-sidebar");
              document.body.removeAttribute("data-sidebar-size");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "small");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "icon":
              document.body.setAttribute("data-keep-enlarged", "true");
              document.body.classList.add("vertical-collpsed");
              document.body.setAttribute("data-sidebar", "dark");
              document.body.removeAttribute("data-topbar", "dark");
              break;
            case "colored":
              document.body.setAttribute("data-sidebar", "colored");
              document.body.removeAttribute("data-keep-enlarged");
              document.body.classList.remove("vertical-collpsed");
              document.body.removeAttribute("data-sidebar-size");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              document.body.removeAttribute("data-layout-scrollable");
              break;
            case "scrollable":
              document.body.setAttribute("data-layout-scrollable", "true");
              document.body.removeAttribute("data-layout-mode");
              document.body.removeAttribute("data-layout-size")
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
.first-item {
  margin-top: 12px;
}
.term-dropdown {
  /* padding: 10px 24px 5px !important; */
  border-color: #fff;
  font-weight: 900;
}
.time-icon {
  padding: 8px 0;
}
.time-toggle {
  font-size: 23.2px;
  margin: 4px 24px;
  align-items: center;
  vertical-align: middle;
}
.sidebar-subscribe {
  align-items: center;
  text-align: center;
  vertical-align: middle;
  margin: 25px 20px;
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}
.signup-button {
  background-color: #006E33;
  border-color: #006E33;
  padding-inline: 20px;
}
</style>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <SimpleBar
      v-if="!isCondensed"
      :settings="settings"
      class="h-100"
      ref="currentMenu"
      id="my-element"
    >
      <template v-if="!dataLoaded">
        <div v-for="block in 5" :key="block" class="mt-4">
          <div class="font-size-13 score-header placeholder-glow">
            <span class="placeholder col-6"></span>
          </div>
          <div>
            <p class="placeholder-glow">
              <span class="placeholder col-7"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-4"></span>
              <span class="placeholder col-6"></span>
              <span class="placeholder col-8"></span>
            </p>
          </div>
        </div>
      </template>

      <template v-if="dataLoaded && !userData.isSubscribed">
        <div class="container">
          <div class="row sidebar-subscribe">
            You are not currently subscribed to a plan.
          </div>
          <div class="row sidebar-subscribe">
            <Subscribe />
          </div>
        </div>        
      </template>

      <template v-if="dataLoaded && userData.isSubscribed">
        <div class="menu-title first-item">
          {{ $t("sidebar.term.title") }}
        </div>
        <div class="time-toggle">
          <b-dropdown
            class="term-dropdown"
            v-if="userData.preferredTerm"
            id="term-dropdown"
            block
            :text="scopes[userData.preferredTerm]['train']"
            variant="primary"
          >
            <b-dropdown-item @click="setTerm('shortterm')">Short-Term</b-dropdown-item>
            <b-dropdown-item @click="setTerm('mediumterm')">Medium-Term</b-dropdown-item>
            <b-dropdown-item @click="setTerm('longterm')">Long-Term</b-dropdown-item>
          </b-dropdown>
        </div>
        <SideNav />
      </template>
    </SimpleBar>

    <SimpleBar v-else class="h-100">
      <SideNav />
    </SimpleBar>
  </div>
  <!-- Left Sidebar End -->
</template>