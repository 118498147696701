<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { menuItems } from "./menu";
import { authComputed } from "@/state/helpers";

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
      menuData: null,
    };
  },
  mounted: function () {
    if (document.getElementById("side-menu")) new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <span class="mm-collapse">Hello Hello</span>
      <li class="menu-title">
        {{ $t('menuitems.home.title') }}
      </li>
      <li>
        <router-link
          to="/"
          class="side-nav-link-ref"
        >
          <i class="bx bxs-dashboard"></i>
          <span>{{ $t('menuitems.home.dashboard') }}</span>
        </router-link>
      </li>

      <li class="menu-title">
        {{ $t('menuitems.etfs.title') }}
      </li>
      <li v-if="userData.tier == 'premium' || userData.tier == 'pro'">
        <router-link
          to="/etfs/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-list-ul"></i>
          <span>{{ $t('menuitems.etfs.overview') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-list-ul"></i>
          <span>{{ $t('menuitems.etfs.overview') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>
      <li v-if="userData.tier == 'premium' || userData.tier == 'pro'">
        <router-link
          to="/etfs/buy/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-trending-up"></i>
          <span>{{ $t('menuitems.etfs.buy') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-trending-up"></i>
          <span>{{ $t('menuitems.etfs.buy') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>
      <li v-if="userData.tier == 'premium' || userData.tier == 'pro'">
        <router-link
          to="/etfs/sell/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-trending-down"></i>
          <span>{{ $t('menuitems.etfs.sell') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-trending-down"></i>
          <span>{{ $t('menuitems.etfs.sell') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>

      <li class="menu-title">
        {{ $t('menuitems.stocks.title') }}
      </li>
      <li v-if="userData.tier == 'premium' || userData.tier == 'pro'">
        <router-link
          to="/stocks/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-list-ul"></i>
          <span>{{ $t('menuitems.stocks.overview') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-list-ul"></i>
          <span>{{ $t('menuitems.stocks.overview') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>
      <li v-if="userData.tier == 'premium' || userData.tier == 'pro'">
        <router-link
          to="/stocks/buy/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-trending-up"></i>
          <span>{{ $t('menuitems.stocks.buy') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-trending-up"></i>
          <span>{{ $t('menuitems.stocks.buy') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>
      <li v-if="userData.tier == 'premium' || userData.tier == 'pro'">
        <router-link
          to="/stocks/sell/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-trending-down"></i>
          <span>{{ $t('menuitems.stocks.sell') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-trending-down"></i>
          <span>{{ $t('menuitems.stocks.sell') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>

      <li class="menu-title">
        {{ $t('menuitems.crypto.title') }}
      </li>
      <li v-if="userData.tier == 'pro'">
        <router-link
          to="/crypto/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-list-ul"></i>
          <span>{{ $t('menuitems.crypto.overview') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-list-ul"></i>
          <span>{{ $t('menuitems.crypto.overview') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>
      <li v-if="userData.tier == 'pro'">
        <router-link
          to="/crypto/buy/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-trending-up"></i>
          <span>{{ $t('menuitems.crypto.buy') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-trending-up"></i>
          <span>{{ $t('menuitems.crypto.buy') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>
      <li v-if="userData.tier == 'pro'">
        <router-link
          to="/crypto/sell/1"
          class="side-nav-link-ref"
        >
          <i class="bx bx-trending-down"></i>
          <span>{{ $t('menuitems.crypto.sell') }}</span>
        </router-link>
      </li>
      <li v-else>
        <router-link
          to="/"
          class="side-nav-link-ref disabled"
        >
          <i class="bx bx-trending-down"></i>
          <span>{{ $t('menuitems.crypto.sell') }}</span>
          <i class="fas fa-lock float-end fs-5"></i>
        </router-link>
      </li>

      <li class="menu-title">
        {{ $t('menuitems.support.title') }}
      </li>
      <li>
        <a
          class="side-nav-link-ref"
          href="mailto:support@bulldata.ai"
        >
          <i class="bx bx-envelope"></i>
          <span>{{ $t('menuitems.support.contact') }}</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- Sidebar -->
</template>