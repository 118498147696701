export const menuItems = [
    {
        id: 1,
        label: "menuitems.home.title",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.home.dashboard",
        icon: "bxs-dashboard",
        link: "/"
    },
    {
        id: 10,
        label: "menuitems.stocks.title",
        isTitle: true
    },
    {
        id: 11,
        label: "menuitems.stocks.overview",
        icon: "bx-list-ul",
        link: "/stocks/1"
    },
    {
        id: 12,
        label: "menuitems.stocks.buy",
        icon: "bx-trending-up",
        link: "/stocks/buy/1"
    },
    {
        id: 13,
        label: "menuitems.stocks.sell",
        icon: "bx-trending-down",
        link: "/stocks/sell/1"
    },
    {
        id: 20,
        label: "menuitems.etfs.title",
        isTitle: true
    },
    {
        id: 21,
        label: "menuitems.etfs.overview",
        icon: "bx-list-ul",
        link: "/etfs/1"
    },
    {
        id: 22,
        label: "menuitems.etfs.buy",
        icon: "bx-trending-up",
        link: "/etfs/buy/1"
    },
    {
        id: 23,
        label: "menuitems.etfs.sell",
        icon: "bx-trending-down",
        link: "/etfs/sell/1"
    },
    {
        id: 30,
        label: "menuitems.crypto.title",
        isTitle: true
    },
    {
        id: 31,
        label: "menuitems.crypto.overview",
        icon: "bx-list-ul",
        link: "/crypto/1"
    },
    {
        id: 32,
        label: "menuitems.crypto.buy",
        icon: "bx-trending-up",
        link: "/crypto/buy/1"
    },
    {
        id: 33,
        label: "menuitems.crypto.sell",
        icon: "bx-trending-down",
        link: "/crypto/sell/1"
    },
];
